import React from 'react';

import './App.css';
import img1 from "./1.jpeg"
import img2 from "./2.png"





function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={"https://media.giphy.com/media/oh6TVsqQU1IvOMgsqr/giphy.gif"} alt="logo" />
       <h2> trust in sark</h2>

      </header>
    </div>
  );
}

export default App;
